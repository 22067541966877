import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import SEO from '../components/global/SEO'
import { H2 } from '../components/global/Headings'
import { Section } from '../components/global/Section'
import TourItems from '../components/tours/TourItems'
import Button from '../components/global/Button'
import RichText from '../components/global/RichText'
import { HeroSlider } from '../components/global/Hero';

const IndexPage = ({ location, data }) => {
  const pageData = data.allContentfulSite.edges[0].node
  return (
    <Layout>
      <SEO title={pageData.seoTitle} description={pageData.seoDescription} image={pageData.seoSocialMediaImage.resize.src} />
      <Helmet meta={[
          {
            name: "google-site-verification",
            content: "hU_DPv5Ca9LagTyFNuWhZkZr133Vmd6xDxcirZcH1FU"
          }
        ]} 
      />
      <div className="-mt-20 sm:-mt-32 md:-mt-40 z-5">
        <HeroSlider slides={pageData.heroSlider} location={location} />
      </div>
      <Section>
        <H2 icon>{pageData.featuredTour.title}</H2>
        <div className="flex items-center flex-col md:flex-row">
          <Img fluid={pageData.featuredTour.featuredImage.fluid} alt={pageData.featuredTour.featuredImage.description} className="block w-full md:w-1/2 md:mr-6 mb-6 md:mb-0" />
          <div className="md:w-1/2 md:ml-6">
            <div className="mb-6">
              <RichText json={pageData.featuredTourDescription.json} />
            </div>
            {/* <Link to={`/coach-tours/${pageData.featuredTour.slug}`} className="btn btn-green mr-6">View Dates</Link> */}
            <Link to={`/coach-tours/${pageData.featuredTour.slug}`} title={pageData.featuredTour.title} className="btn btn-gray">More Info</Link>
          </div>
        </div>
      </Section>

      <Section>
        <H2 icon><span className="text-green-500">Ireland's</span> Most Popular Tour Locations</H2>
        
        {pageData.featuredLocations.map((location, index) => (
          <Link to={`/coach-tours/${pageData.featuredTour.slug}`} title={pageData.featuredTour.title} key={`location-${index}`} className={`text-black no-underline lg:w-2/3 flex items-center flex-col md:flex-row mb-16 ${(index % 2 !== 0) ? 'lg:flex-row-reverse lg:ml-auto lg:mr-20' : 'lg:ml-20'}`}>
            <Img fluid={location.featuredImage.fluid} alt={location.featuredImage.description} className={`block w-full mb-8 md:mb-0 md:w-1/2 md:mr-6 shadow ${(index % 2 !== 0) && 'lg:mr-0 lg:ml-6'}`} />
            <div className={`md:w-1/2 md:ml-6 ${(index % 2 !== 0) && 'lg:mr-0 lg:ml-6'}`}>
              <h3 className="mb-4 text-lg">{location.title}</h3>
              <p className="text-gray-500">{location.shortDescription.shortDescription}</p>
            </div>
          </Link>
        ))}
      </Section>

      <div className="bg-green-500">
        <div className="container mx-auto text-center px-4 py-10">
          <h3 className="text-white pb-6 text-lg leading-loose">{pageData.secondaryFeaturedTourBanner}</h3>
          <FontAwesomeIcon icon={faStar} className="text-yellow-500 mx-1" /><FontAwesomeIcon icon={faStar} className="text-yellow-500 mx-1" /><FontAwesomeIcon icon={faStar} className="text-yellow-500 mx-1" /><Button to={pageData.secondaryFeaturedTourBannerLink} title="View Tour Dates" className="btn btn-gray mx-4">View Dates</Button><FontAwesomeIcon icon={faStar} className="text-yellow-500 mx-1" /><FontAwesomeIcon icon={faStar} className="text-yellow-500 mx-1" /><FontAwesomeIcon icon={faStar} className="text-yellow-500 mx-1" />
        </div>
      </div>

      {data.popularTours &&
        <Section>
          <H2 icon>More Popular <span className="text-green-500">Ireland</span> Tours</H2> 
          <TourItems tours={data.popularTours.edges} />
        </Section>
      }

      {data.otherTours &&
        <Section>
          <H2 icon><span className="text-green-500">Ireland</span> Tours Day Trips</H2>      
          <TourItems tours={data.otherTours.edges} />
        </Section>
      }

    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    allContentfulSite(limit: 1) {
      edges {
        node {
          title
          heroSlider {
            title
            backgroundImage {
              fixed(width: 1600, quality: 100) {
                src
              }
            }
            content {
              json
            }
            link
            linkText
          }
          seoTitle
          seoDescription
          seoSocialMediaImage {
            resize(width: 1200, height: 630) {
              src
            }
          }
          featuredTour {
            id
            title
            slug
            featuredImage {
              description
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
          featuredTourDescription {
            json
          }
          featuredLocations {
            title
            slug
            featuredImage {
              description
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            shortDescription {
              shortDescription
            }
              
          }
          secondaryFeaturedTourBanner
          secondaryFeaturedTourBannerLink
        }
      }
    }
    featuredTour: contentfulTour(slug: { regex: "/causeway/" }) {
      id
      title
      slug
      featuredImage {
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      shortDescription {
        shortDescription
      }
    }
    popularTours: allContentfulTour(limit: 3, sort: {
      fields: [sortOrder],
      order: ASC
    }, filter: {
      menuCategory: {
        eq: "Popular Tours"
      }
    }) {
      edges {
        node {
          title
          slug
          featuredImage {
            description
            resize(width: 450, height: 300) {
              src
            }
          }
          shortDescription {
            shortDescription
          }
        }
      }
    }
    otherTours: allContentfulTour(limit: 3, sort: {
      fields: [sortOrder],
      order: ASC
    }, filter: {
      menuCategory: {
        ne: "Popular Tours"
      }
    }) {
      edges {
        node {
          title
          slug
          featuredImage {
            description
            resize(width: 450, height: 300) {
              src
            }
          }
          shortDescription {
            shortDescription
          }
        }
      }
    }
  }
`

export default IndexPage
